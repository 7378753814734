<template>
  <div class="body fixed mobile">
    <!-- Loading Spinner -->    
    <!-- Header -->
    <section class="header">
      <div class="page-title-wrap">
          <a  @click.prevent="$router.push('/home')"  class="back-page"></a>
          <div class="page-title">我的條碼</div>
          <div>&nbsp;</div>
      </div>
    </section>
    <!-- Main -->
    <section class="main barcode">
      <!-- <a class="button fit rounded" @click.prevent="popCode()"
                ><span class="fS">手動輸入代碼</span></a
            > -->
      <div class="board-wrap">
        <div class="flexH between width">
          <div class="bw-title">會員條碼</div>
          <div class="flexH between w-20 ">
            <a
            :class="{ active: memberCodeType === 'qrcode', icons:true }"
            @click.prevent="memberCodeType = 'qrcode'"
          >
            <font-awesome-icon
              icon="fa-solid fa-qrcode"
              size="xl"
            ></font-awesome-icon>
          </a>
          <a
            :class="{ active: memberCodeType === 'barcode' , icons:true }"
            @click.prevent="memberCodeType = 'barcode'"
          >
            <font-awesome-icon
              icon="fa-solid fa-barcode"
              size="xl"
            ></font-awesome-icon>
          </a>
          </div>
        </div>
        <div class="center py-8">
          <QrcodeVue
            v-if="memberCodeType === 'qrcode'"
            :value="memberCode"
            size="250"
            level="H"
          />
          <barcode v-else-if="!memberCode" value="000000000000000000000" format="code128" width="2" />
          <barcode v-else :value="memberCode" format="code128" width="2">
            {{ memberCode }}
          </barcode>
        </div>
        
      </div>

      <div class="board-wrap">
        <div class="flexH between width">
          <div class="bw-title">載具條碼</div>       
        </div>
        <div class="center py-8">
          <barcode v-if="userInfo?.cardNo" :value="userInfo?.cardNo" format="code128" width="2">
            {{ userInfo?.cardNo }}
          </barcode>
          <div v-else>
            <div class="bw-title2 mb-8">您尚未設定手機載具</div>
            <a class="bw-button" @click.prevent="$router.push('/carrier/edit')">點此前往設定</a>
          </div>
          <div v-if="userInfo?.cardNo" class="text-right text-primary" @click.prevent="popDelete()">移除載具設定</div>
        </div>
        
      </div>
     
    </section>
    <section id="popSuccess" class="popup">
      <div class="popBody">
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain text-center">
          <div class="icon success">
            <font-awesome-icon
              icon="fa-solid fa-circle-check"
              size="2x"
            ></font-awesome-icon>
          </div>
          <span class="fL"> {{ successMessage }} </span>
        </section>
        <section class="popFooter">
          <a class="button submit rounded" @click.prevent="popClose">
            <span class="fM">確定</span>
          </a>
        </section>
      </div>
    </section>
    <section id="popDelete" class="popup">
      <div class="popBody">
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain text-center">
          <div class="icon error">
            <font-awesome-icon icon="fa-solid fa-circle-exclamation" size="2x"/>
          </div>
          <div class="fL">確定移除載具設定？</div>
          <div class="fS gray">移除設定後部分功能將無法正常使用</div>
        </section>
        <section class="popFooter">
          <a class="button white rounded" @click.prevent="popDeleteClose">
            <span class="fM">取消</span>
          </a>
          <a class="button error rounded" @click.prevent="removeCarrier">
            <span class="fM">確定移除</span>
          </a>
        </section>
      </div>
    </section>
   
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueBarcode from 'vue-barcode';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'BarcodePopup',
  components: {
    barcode: VueBarcode,
    QrcodeVue,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      memberCode: '',
      intervalId: '',
      defaultTimerSec: 300,
      timer: 300,
      codeType: 'qrcode',
      tab: 'member',
      memberCodeType :'qrcode',
      carrierCodeType :'',
      successMessage: "",
    };
  },
  mounted() {    
    this.refreshMemberCode();
    document.body.style.overflow = "hidden";    
    
  },
  beforeDestroy() {
    this.clearTimer();
    document.body.style.overflow = "scroll";
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    userDisplayedName() {
      return this.userInfo.name;
    },
    // userIdNo() {
    //   return this.user?.user?._json
    //     ? this.hideIdentityNumber(this.user.user._json.idno)
    //     : '';
    // },
    userImageUrl() {
      return this.userInfo ? this.userInfo.picture : '';
    },
    userCardNo() {
      return this.userInfo?.cardNo ? this.userInfo?.cardNo.trim() : null;
    },
  },
  methods: {
    callUserDataAPI() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUserData() {
      this.callUserDataAPI()
        // this.dummyUserData()
        .then((res) => {
          this.$store.commit("updateUserInfo", res.data);
          this.userData = res.data;
          this.originalCardNo = res.data.cardNo ? res.data.cardNo : null;
          this.$forceUpdate()
        })
        .catch((err) => {
          console.log('err: ', err);
          this.$router.push('/error');
        });
    },
    hideIdentityNumber(str) {
      let pattern = /(^[a-zA-Z]{1})(\d{7})(\d{2})/g;
      return str.replace(pattern, function (match, start, middle, tail) {
        return start + '*'.repeat(middle.length) + tail;
      });
    },
    displayTimerToMinutes() {
      let mins = Math.floor(this.timer / 60);
      let secs = (this.timer % 60).toString().padStart(2, '00');
      return `${mins}:${secs}`;
    },
    getMemberCode() {
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/one-time-keys/pay`,
        method: 'POST',
      };
      return this.$http(config);
    },
    clearTimer() {
      clearInterval(this.intervalId);
      this.timer = this.defaultTimerSec;
      this.memberCode = '';
      this.intervalId = '';
    },
    periodRefreshMemberCode() {
      this.clearTimer();
      this.intervalId = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.refreshMemberCode();
        }
      }, 1000);
    },
    refreshMemberCode() {
      this.periodRefreshMemberCode();
      this.getMemberCode()
        .then((memberCodeRes) => {
          this.memberCode = memberCodeRes.data.oneTimeKey;
        })
        .catch((memberCodeErr) => {
          console.log('memberCodeErr: ', memberCodeErr);
        });
    },
    closePopup() {
      this.$emit('closePopup');
    },
    popSuccess(msg) {
      this.successMessage = msg;
      var popSuccess = document.getElementById('popSuccess');
      popSuccess.style.display = 'flex';
    },
    popClose() {
      var popSuccess = document.getElementById('popSuccess');
      popSuccess.style.display = 'none';
    },
    popDelete() {
      var popDelete = document.getElementById('popDelete');
      popDelete.style.display = 'flex';
    },
    popDeleteClose() {
      var popDelete = document.getElementById('popDelete');
      popDelete.style.display = 'none';
    },
    removeCarrier() {
      this.isProcessing = true;
      this.popDeleteClose();
      let data = Object.assign({}, this.userData);
      data.cardNo = null;
      data.cardEncrypt = null;
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        method: 'PUT',
        data: data,
      };
      this.$http(config)
        .then((res) => {
          console.log('res: ', res);
          this.popSuccess('載具設定成功');
          this.getUserData();
          this.cardEncrypt = '';
        })
        .catch((err) => {
          console.log('err: ', err);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>
<style>
.vue-barcode-element {
  width: 100%;
}
</style>
